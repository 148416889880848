<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">
            <span>Пополнить баланс</span>
            <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
        </h1>
    </div>
    <div class="tab-arrow">
        <div class="tab horizontal account default-color">
            <router-link to="/dashboard/refill/cryptocurrency" class="tab-button">Криптовалюты</router-link>
            <router-link to="/dashboard/refill/fiat" class="tab-button">Фиат</router-link>
            <router-link to="/dashboard/refill/terminals" class="tab-button">Терминалы</router-link>
            <router-link to="/dashboard/refill/cash" class="tab-button">Банк</router-link>
        </div>
    </div>
    <div class="dashboard-wrap">
        <div class="dashboard-list">
            <div class="row">
                <template v-if="category === 'cryptocurrency' && currencies">
                    <template v-for="item in currencies" :key="item">
                        <div class="col-6 col-md-4 col-lg-3 col-xl-3" v-if="item.can_deposit === true">
                            <div class="list-item" v-on:click="getDepositAddress(item)">
                                <img :src="item.logo" :alt="item.name">
                                <div class="list-item-wrap">
                                    <div>
                                        <b>{{ item.currency }}</b>
                                        <span>{{ item.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-if="category === 'fiat'">
                    <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-for="item in getMethods" :key="item">
                        <div class="list-item" v-on:click="getMethodDetail(item)">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>{{ item.currency }}</b>
                                    <span class="active">{{ item.name }}</span>
                                </div>
                                <a class="list-item-button">{{ item.commission }}%</a>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="category === 'terminals'">
                    <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-for="item in getMethods" :key="item">
                        <div class="list-item" v-on:click="getMethodDetail(item)">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>{{ item.currency }}</b>
                                    <span class="active">{{ item.name }}</span>
                                </div>
                                <a class="list-item-button">{{ item.commission }}%</a>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="category === 'cash'">
                    <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-for="item in getMethods" :key="item">
                        <div class="list-item" v-on:click="getMethodDetail(item)">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>{{ item.currency }}</b>
                                    <span class="active">{{ item.name }}</span>
                                </div>
                                <a class="list-item-button">{{ item.commission }}%</a>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="modal-window" v-if="modalStatus">
            <div class="modal-close" v-on:click="closeModal"></div>
            <div class="modal-content modal-refill-crypto" v-if="category === 'cryptocurrency'">
                <div class="modal-close-btn" v-on:click="closeModal"></div>
                <h1 class="modal-title text-center">{{ detail.name }} депозитный адрес</h1>
                <p class="modal-text" v-html="info.info?.description"></p>
                <div class="form">
                    <div class="modal-qr-image">
                        <img :src="depositAddress.qrcode_url" :alt="detail.name">
                    </div>
                    <label class="form-label">Выберите сеть</label>
                        <div class="form-group mb-3">
                            <select class="form-input" v-on:change="setNetwork($event)" :disabled="loading">
                                <template v-for="(item, index) in networks" :key="index">
                                    <option :value="item.network" :selected="item.network === 'TRC20'">{{ item.name }} MIN: {{ item.min_deposit }} {{ detail.currency }}</option>
                                </template>
                            </select>
                        </div>
                    <label class="form-label">{{ detail.name }} {{ detail.currency }} Адрес</label>
                    <div class="form-group">
                        <input type="text" class="form-input default-input" :value="depositAddress.deposit_address" ref="address" readonly :disabled="loading">
                    </div>
                    <div class="text-center">
                        <button class="button d-inline-block lg" v-on:click="copy('address')" :disabled="loading">Скопировать</button>
                    </div>
                </div>
            </div>
            <div class="modal-content" v-if="category === 'fiat' && !fiatDetail">
                <h1 class="modal-title text-center mb-4">Пополнить через "{{ detail.name }}"</h1>
                <template v-if="!detail.info.description">
                    <div class="modal-wrap">
                        <div class="row">
                            <div class="col-12 col-md-6 m-mb">
                                <div class="profile-info-column mt-0 icon-none">
                                    <div>
                                        <span>Минимальная сумма</span>
                                        <b>{{ detail.min_qty }} {{ detail.currency }}</b>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="profile-info-column mt-0 icon-none">
                                    <div>
                                        <span>Комиссия</span>
                                        <b>{{ detail.commission }}%</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form mt-4">
                        <label class="form-label">Введите сумму в <b>{{ detail.currency }}</b></label>
                        <div class="form-group">
                            <input type="number" class="form-input" v-model="amount" placeholder="Введите сумму" required>
                        </div>
                        <button class="button block lg mt-4" v-on:click="createFiatMethod" :disabled="loading">Продолжить</button>
                    </div>
                </template>
                <template v-else>
                    <div class="modal-wrap">
                        <p class="modal-text text-center" v-html="detail.info.description"></p>
                        <button class="button block lg" v-on:click="closeModal">Закрыть</button>
                    </div>
                </template>
            </div>
            <div class="modal-content d-block" v-if="category === 'fiat' && fiatDetail">
                <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
                <div class="dashboard-table px-0">
                    <div class="dashboard-table-row table-row-v2">
                        <div class="dashboard-table-column">Сумма к зачислению</div>
                        <div class="dashboard-table-column">{{ fiatDetail.confirm.debit }} USDT</div>
                    </div>
                    <div class="dashboard-table-row table-row-v2">
                        <div class="dashboard-table-column">Сумма к оплате</div>
                        <div class="dashboard-table-column">{{ fiatDetail.confirm.amount }} {{ fiatDetail.confirm.currency }}
                        </div>
                    </div>
                    <div class="dashboard-table-row table-row-v2">
                        <div class="dashboard-table-column">Комиссия</div>
                        <div class="dashboard-table-column">{{ fiatDetail.confirm.commission }} {{ fiatDetail.confirm.currency }}</div>
                    </div>
                </div>
                <template v-if="fiatDetail.confirm.qrCode && detail.slug == 'mkassa'">
                    <div class="dashboard-table-row table-row-v2 mb-3">
                        <div class="dashboard-table-column text-wrap text-center">Для проведения платежа, отсканируйте QR-код в приложении банка</div>
                    </div>
                    <div class="modal-qr-image modal-qr-mkassa mb-3">
                        <img :src="fiatDetail.confirm.qrCode" :alt="fiatDetail.confirm.description">
                    </div>
                </template>
                <template v-if="fiatDetail.confirm.qrCode && detail.slug == 'qrmbank'">
                    <div class="dashboard-table-row table-row-v2 mb-3">
                        <div class="dashboard-table-column text-wrap text-center d-block">
                            <p>Совершая оплату вы  подтверждаете, что оплачиваете со своего счета МБанк и согласны на ручную  проверку транзакции, которая может занять до 8 часов.</p>
                            <hr>
                            <p>Для проведения платежа, отсканируйте QR-код в приложении банка:</p>
                        </div>
                    </div>
                    <div class="modal-qr-image modal-qr-mkassa mb-3">
                        <img :src="fiatDetail.confirm.qrCode" :alt="fiatDetail.confirm.description">
                    </div>
                </template>
                <template v-if="fiatDetail.confirm.deepLink">
                    <div class="dashboard-table-row table-row-v2 justify-content-center mb-3">
                        <div class="dashboard-table-column text-wrap">Или откройте ссылку для оплаты в MBank:</div>
                    </div>
                    <a :href="fiatDetail.confirm.deepLink" class="mbank-button"></a>
                </template>
                <div class="custom-form" v-if="fiatDetail.form" v-html="fiatDetail.form"></div>
            </div>
            <div class="modal-content" v-if="category === 'terminals'">
                <h1 class="modal-title text-center mb-4">Пополнить через "{{ detail.name }}"</h1>
                <p class="modal-text" v-html="detail.info.description"></p>
                <button class="button block lg" v-on:click="closeModal">Закрыть</button>
            </div>
            <div class="modal-content" v-if="category === 'cash'">
                <h1 class="modal-title text-center mb-4">Пополнить через "{{ detail.name }}"</h1>
                <p class="modal-text text-center" v-html="detail.info.description"></p>
                <button class="button block lg" v-on:click="closeModal">Закрыть</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalStatus: false,
            category: '',
            detail: '',
            amount: '',
            info: '',
            intervalId: false,
            networks: ''
        }
    },
    created() {
        this.getCurrentCategory()
        if (this.$route.query['currency']) {
            this.$store.dispatch('getCurrencies')
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        currencies() {
            return this.$store.getters.getCurrencies
        },
        getMethods() {
            return this.$store.getters.getMethods
        },
        depositAddress() {
            return this.$store.getters.getDepositAddress
        },
        fiatDetail() {
            return this.$store.getters.getFiatMethod
        },
        getMKassaPaymentStatus() {
            return this.$store.getters.getMKassaPaymentStatus
        }
    },
    watch: {
        $route() {
            this.getCurrentCategory()
        },
        currencies() {
            if (this.$route.query['currency']) {
                this.getDepositAddress(this.filterCurrency(this.currencies, this.$route.query['currency']))
            }
        },
        depositAddress() {
            this.showModal()
        },
        fiatDetail() {
            if (this.fiatDetail && this.fiatDetail.confirm.qrCode !== '' && this.fiatDetail.confirm?.tracking === true) {
                this.checkMKassaPayment()
            }
        },
        getMKassaPaymentStatus() {
            if (this.getMKassaPaymentStatus.status.value == '2') {
                this.$store.dispatch('notification', { message: 'Оплата прошла успешно!', type: 'success' })
                this.$store.dispatch('clearFiatMethod')
                this.$router.push('/dashboard/operations')
                clearInterval(this.intervalId)
            }
            if (this.getMKassaPaymentStatus.status.value == '3') {
                this.$store.dispatch('notification', { message: 'Оплата отменена!' })
                this.$store.dispatch('clearFiatMethod')
                this.$router.push('/dashboard/operations')
                clearInterval(this.intervalId)
            }
        }
    },
    methods: {
        filterCurrency(list, currency) {
            return list.find((el) => { return el.currency == currency })
        },
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
            if (this.category === 'fiat') {
                this.amount = ''
                this.$store.dispatch('clearFiatMethod')
            }
        },
        getCurrentCategory() {
            this.category = this.$router.currentRoute.value.params.category
            if (this.category !== undefined) {
                this.$store.dispatch('getMethods', this.category)
            }
        },
        getDepositAddress(item) {
            this.detail = item
            this.networks = item.networks
            console.log(this.networks)
            if (this.getMethods !== '' && this.getMethods !== undefined) {
                this.info = Object.values(this.getMethods).find((el) => { return el.currency == item.currency })
                this.$store.dispatch('getDepositAddress', {slug: item?.slug})
            }
        },
        getMethodDetail(item) {
            this.detail = item
            this.showModal()
        },
        createFiatMethod() {
            if (this.amount < this.detail.min_qty) {
                this.$store.dispatch('notification', { message: 'Минимальная сумма пополнения ' + this.detail.min_qty + ' ' + this.detail.currency })
            } else {
                const data = {
                    sum: this.amount,
                    method: this.detail.slug
                }
                this.$store.dispatch('createFiatMethod', data)
            }
        },
        copy(ref) {
            this.$refs[ref].select()
            document.execCommand('copy')
            this.$refs[ref].blur()
            window.getSelection().removeAllRanges()
            this.$store.dispatch('notification', { message: 'Скопировано', type: 'success' })
        },
        checkMKassaPayment() {
            const self = this
            this.intervalId = setTimeout(() => {
                self.$store.dispatch('checkMKassaPayment', { 'guid': self.fiatDetail.confirm.id })
                this.checkMKassaPayment()
            }, 5000)
        },
        setNetwork(event) {
            this.$store.dispatch('getDepositAddress', {slug: this.detail?.slug, network: event.target.value})
        },
    }
}
</script>