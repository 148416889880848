<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">Оплата услуг — {{ servicesList && servicesList[0].category?.title }}</h1>
        <div class="d-block mb-4 pt-2 col-12 col-md-8 col-lg-6 col-xl-6">
            <input type="search" class="form-input search-input" v-model="searchValue" placeholder="Поиск ...">
        </div>
        <div class="dashboard-list">
            <div class="row">
                <template v-for="item in servicesList" :key="item">
                    <router-link :to="'/dashboard/payment-services/' + this.category + '/' + item.id" class="col-6 col-md-4 col-lg-3 col-xl-3 payment-service-wrap" v-if="search(item)">
                        <div class="list-item">
                            <img :src="item.logo ? item.logo : PaymentIcon" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <span class="active text-white">{{ item.name }}</span>
                                </div>
                                <a class="list-item-button text">{{ item.commission }}%</a>
                            </div>
                        </div>
                    </router-link>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentIcon from '../../assets/img/icons/box.svg'

export default {
    data() {
        return {
            PaymentIcon: PaymentIcon,
            searchValue: '',
            category: ''
        }
    },
    mounted() {
        this.category = this.$router.currentRoute.value.params.category
        this.$store.dispatch('getPaymentServices', this.category)
    },
    computed: {
        servicesList() {
            return this.$store.getters.getPaymentServices
        }
    },
    methods: {
        search(item) {
            return (item.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1 || item.title.toLowerCase().search(this.searchValue.toLowerCase()) !== -1) ? true : false
        }
    }
}
</script>