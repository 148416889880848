<template>
  <Notification></Notification>
  <router-view></router-view>
</template>

<script>
import Notification from '@/components/Notification.vue'

export default {
  name: 'App',
  components: {
    Notification
  },
  created() {
    const self = this
    setInterval(function () {
      if (localStorage.getItem('isAuth') && Math.round(Date.now() / 1000) > localStorage.getItem('expires')) {
        localStorage.clear()
        self.$router.push('/auth/login?SessionExpired=true')
      }
      if (!navigator.onLine || window.addEventListener('offline', null)) {
        self.$store.dispatch('notification', { message: 'Отсутствует подключение к интернету' })
      }
    }, 3000)
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = 'XBT - Покупка. Продажа. Обмен криптовалюты'
      }
    },
  }
}
</script>
