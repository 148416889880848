<template>
    <Header></Header>
    <div class="container">
        <div class="dashboard-main min-height z-indexz">
            <LeftBar></LeftBar>
            <div class="right-bar">
                <router-view></router-view>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import LeftBar from '@/components/Account/LeftBar.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {}
    },
    components: {
        Header,
        LeftBar,
        Footer
    },
    watch: {
        $route() {
            this.closeMenu()
        }
    },
    methods: {
        showMenu() {
            this.status = true
        },
        closeMenu() {
            this.status = false
        }
    }
}
</script>