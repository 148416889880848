<template>
    <Header></Header>
    <div class="container padding-top-default">
        <div class="header-pagination">
            <RouterLink to="/">Главная</RouterLink>
            <a>FAQ</a>
        </div>
        <h1 class="title">Часто задаваемые вопросы</h1>
    </div>
    <div class="tab">
        <div class="container">
            <template v-for="(section, faqCategory) in FAQList" :key="faqCategory">
                <button class="tab-button lg" :class="category === faqCategory && 'active'"
                    v-on:click="category = faqCategory">{{ section[0].name }}</button>
            </template>
        </div>
    </div>
    <div class="container min-height">
        <div class="faq faq-page">
            <div class="py-4"></div>
            <template v-for="(section, faqCategory) in FAQList" :key="faqCategory">
                <div class="faq-wrap" v-if="category === faqCategory">
                    <template v-for="(faq, index) in section" :key="index++">
                        <div class="faq-box" :class="index == faqIndex && 'active'" v-on:click="open(index)">
                            <span>{{ faq.question }}</span>
                            <p v-html="faq.answer"></p>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            faqIndex: 0,
            category: ''
        }
    },
    components: {
        Header,
        Footer
    },
    created() {
        if (this.$store.getters.getFAQList) {
            this.category = Object.keys(this.$store.getters.getFAQList)[0]
        }
    },
    computed: {
        FAQList() {
            return (this.$store.getters.getFAQList)
                ? this.$store.getters.getFAQList
                : this.$store.dispatch('getFAQList')
        }
    },
    watch: {
        FAQList() {
            this.category = Object.keys(this.$store.getters.getFAQList)[0]
            console.log(this.category)
        }
    },
    methods: {
        open(index) {
            this.faqIndex = this.faqIndex == index ? 0 : index
        }
    }
}
</script>