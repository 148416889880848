<template>
    <div class="container z-index auth-wrap">
        <div class="row">
            <div class="col-md-5 auth-form">
                <h1 class="title text-center">Добро пожаловать</h1>
                <p class="subtitle text-center">Подтверждение почты</p>
                <p class="text text-center">Мы отправили вам на почту ссылку для подтверждения аккаунта.</p>
                <p class="text text-center">Пожалуйста, перейдите по ссылке чтобы активировать аккаунт</p>
                <router-link to="/auth/login" class="button button-default d-inline-block lg">Продолжить</router-link>
                <span class="form-agree text-center">Не пришел код? <a class="cursor" :disabled="loading" v-on:click="resend">Отправить еще раз</a></span>
            </div>
            <div class="col-md-7 d-flex justify-content-center align-items-center">
                <div class="auth-icon"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: ''
        }
    },
    created() {
        this.email = this.$router.currentRoute.value.params.email
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        }
    },
    methods: {
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        resend() {
            if (!this.email || this.email === '') {
                this.notification('Введите E-mail')
            } else {
                this.$store.dispatch('resend', { email: this.email })
            }
        },
    }
}
</script>