<template>
    <Header></Header>
    <div class="min-height padding-top-default auth-cover">
        <router-view></router-view>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer
    },
    mounted() {
        if(this.$router.currentRoute.value.path.split('/')[2] === '') {
            this.$router.push('/auth/login')
        }
    }
}
</script>