<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title" v-if="currency !== 'XBT'">Вывести {{ detail?.name }}</h1>
        <h1 class="dashboard-title" v-else>Вывести {{ detail?.name }} на Tether USDT</h1>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 col-xl-6">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="dashboard-personal-wrap border-0">
                            <div class="profile-info-column mt-0 icon-none">
                                <div>
                                    <span>Срок исполнения</span>
                                    <b>Моментально</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="dashboard-personal-wrap border-0">
                            <div class="profile-info-column mt-0 icon-none">
                                <div>
                                    <span>Минимальная сумма</span>
                                    <b>{{ network.min_qty }} {{ currency }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="currency !== 'XBT'">
                        <div class="dashboard-personal-wrap border-0">
                            <div class="profile-info-column mt-0 icon-none">
                                <div>
                                    <span>Комиссия</span>
                                    <b>{{ info.commission }}%</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="currency !== 'XBT'">
                        <div class="dashboard-personal-wrap border-0">
                            <div class="profile-info-column mt-0 icon-none">
                                <div>
                                    <span>Комиссия сети</span>
                                    <b>{{ network.commission }} {{ currency }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form v-on:submit.prevent="onsubmit" class="form" autocomplete="off">
                    <template v-if="currency !== 'XBT'">
                        <label class="form-label">Выберите сеть</label>
                        <div class="form-group">
                            <select class="form-input" v-on:change="setNetwork($event)">
                                <template v-for="(item, index) in info?.networks" :key="index">
                                    <option :value="index" :selected="item.network === 'TRC20'">{{ item.name }}</option>
                                </template>
                            </select>
                        </div>
                    </template>
                    <label class="form-label d-flex justify-content-between">Сумма к списанию <span><span class="md-none">Баланс:</span> <b class="max-balance" v-on:click="setMaxAmount">{{ balance }}</b> {{ currency }}</span></label>
                    <div class="form-group mb-4 position-relative">
                        <input type="number" name="amount" class="form-input" step="any" placeholder="Введите сумму" v-model="amount" @input="calculate" required :disabled="loading">
                        <span class="form-icon icon-max-amount ease" v-on:click="setMaxAmount"></span>
                    </div>
                    <label class="form-label">Сумма к зачислению</label>
                    <div class="form-group">
                        <input type="number" name="quantity" class="form-input" placeholder="Сумма к зачислению" v-model="quantity" disabled>
                    </div>
                    <template v-if="currency !== 'XBT'">
                        <label class="form-label">Введите адрес {{ currency }} кошелька</label>
                        <div class="form-group mb-4 position-relative">
                            <input type="text" name="address" class="form-input" placeholder="Введите адрес" v-model="address" required :disabled="loading">
                            <span class="form-icon icon-paste ease" v-on:click="paste"></span>
                        </div>
                    </template>
                    <button class="button block lg my-4" :disabled="loading" type="submit">Продолжить</button>
                </form>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-xl-6">
                <div class="dashboard-personal-wrap">
                    <WithdrawText />    
                </div>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <form class="modal-content" v-on:submit.prevent="confirmWithdraw" autocomplete="off">
            <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
            <div class="dashboard-table px-0" v-if="confirm && currency !== 'XBT'">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ confirm.amount }} {{ currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ quantity }} {{ currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Комиссия</div>
                    <div class="dashboard-table-column">{{ confirm.commission }} {{ currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2 justify-content-center">
                    <div class="dashboard-table-column text-wrap">{{ address }}</div>
                </div>
            </div>
            <div class="dashboard-table px-0" v-if="confirm && currency === 'XBT'">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ confirm.amount }} {{ currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ quantity }} USDT</div>
                </div>
            </div>
            <template v-if="twofactor">
                <label class="form-label mt-3">Google 2Fa Authenticator</label>
                <div class="form-group">
                    <input type="number" class="form-input" ref="code" placeholder="Введите защитный код" :disabled="loading" required>
                </div>
            </template>
            <button class="button block lg" type="submit" :disabled="loading">Подтвердить</button>
        </form>
    </div>
</template>

<script>
import WithdrawText from '@/components/Account/WithdrawText'

export default {
    data() {
        return {
            modalStatus: false,
            quantity: '',
            currency: '',
            address: '',
            balance: '',
            network: '',
            amount: ''
        }
    },
    components: {
        WithdrawText
    },
    created() {
        this.$store.dispatch('getProfile')
        this.currency = this.$router.currentRoute.value.params.currency
        this.address = this.currency === 'XBT' ? 'Internal transfer' : ''
        this.$store.dispatch('getWithdrawDetail', this.currency)
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        profile() {
            return this.$store.getters.getProfile
        },
        detail() {
            return (this.$store.getters.getCurrencies)
            ? this.filterCurrency(this.$store.getters.getCurrencies, this.currency)
            : this.$store.dispatch('getCurrencies')
        },
        info() {
            return this.$store.getters.getWithdrawDetail
        },
        confirm() {
            return this.$store.getters.getWithdrawConfirm
        },
        twofactor() {
            return this.$store.getters.twofactor
        }
    },
    watch: {
        detail() {
            if(this.detail === undefined || this.detail.can_withdraw !== true) {
                this.$router.push('/dashboard/withdraw')
            } else {
                this.balance = this.detail.balance
            }
        },
        info() {
            if(this.currency === 'USDT') {
                this.network = Object.values(this.info?.networks).find(function(el) { return el.network === 'TRC20' })
            } else {
                this.network = this.info?.networks[1]
            }
        },
        confirm() {
            this.showModal()
        },
        profile() {
            if (this.profile.verification.value !== '2') {
                this.$router.push('/dashboard/settings/verification')
                this.$store.dispatch('notification', { message: 'Требуется проверка KYC' })
            }
        }
    },
    methods: {
        async paste() {
            const text = await navigator.clipboard.readText()
            this.address = text
        },
        showModal() {
            this.$store.dispatch('twofactor', false)
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
        },
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        filterCurrency(list, currency) {
            return list.find((el) => { return el.currency == currency })
        },
        numberFormat(number, float) {
            var string, result
            if(String(number).includes('.')) {
                string = String(number).split('.')
                result = (float > 0) ? string[0] +'.'+ string[1].substring(0, float) : string[0]
            } else {
                result = number
            }
            return result
        },
        calculate() {
            if(this.amount < parseFloat(this.network.min_qty)) {
                this.quantity = ''
            } else {
                this.quantity = this.amount - this.network.commission
                this.quantity = this.numberFormat(this.quantity, this.detail.decimal)
            }
        },
        setNetwork(event) {
            this.network = this.info.networks[event.target.value]
            this.calculate()
        },
        setMaxAmount() {
            this.amount = this.balance
            this.calculate()
        },
        onsubmit() {
            if(this.quantity < parseFloat(this.network.min_qty)) {
                this.notification('Минимальная сумма ' + this.network.min_qty +' '+ this.currency)
            } else {
                if(!this.address || this.address === '') {
                    this.notification('Введите адрес кошелька')
                } else {
                    const data = {
                        sum: this.amount,
                        currency: this.currency,
                        wallet: this.address,
                        network: this.network.network
                    }
                    this.$store.dispatch('createWithdraw', data)
                }
            }
        },
        confirmWithdraw() {
            const data = {
                'guid': this.confirm.id
            }
            if(this.twofactor) {
                data['2fa_otp'] = String(this.$refs.code.value)
            }
            this.$store.dispatch('confirmWithdraw', data)
        }
    }
}
</script>