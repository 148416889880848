export default {
    state: {
        history: '',
        historyList: ''
    },
    mutations: {
        setHistory(state, payload) {
            state.history = payload.operations
        },
        setHistoryList(state, payload) {
            state.historyList = payload.types
        }
    },
    actions: {
        getHistory({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/operations', data})
            .then(response => response.json())
            .then(data => {
                commit('setHistory', data)
            })
        },
        getHistoryList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/operations/list'})
            .then(response => response.json())
            .then(data => {
                commit('setHistoryList', data)
            })
        },
    },
    getters: {
        history(state) {
            return state.history
        },
        getHistoryTypes(state) {
            return state.historyList
        }
    }
}