import router from "@/router"

export default {
    state: {
        ReviewsList: ''
    },
    mutations: {
        setReviewsList(state, payload) {
            state.ReviewsList = payload.reviews
        }
    },
    actions: {
        async getReviewsList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/reviews'})
            .then(response => response.json())
            .then(data => {
                commit('setReviewsList', data)
            })
        },
        async addReview({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/reviews/add', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    router.push('/reviews')
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        }
    },
    getters: {
        getReviewsList(state) {
            return state.ReviewsList
        }
    }
}