<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">
            <span>Курс валют</span>
            <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
        </h1>
        <div class="col-12 col-md-8 col-lg-8 col-lx-8">
            <div class="dashboard-personal-wrap p-0">
                <template v-for="item in fiat" :key="item">
                    <div class="main-balance-wrap v3">
                        <div class="main-balance-name">
                            <div>
                                <span>USDT/{{ item.currency_t }}</span>
                                <b>Продажа</b>
                            </div>
                        </div>
                        <div class="main-balance-value">
                            <small>1.00 USDT = {{ item.rate_buy }} {{ item.currency_t }}</small>
                            <b>Курс</b>
                        </div>
                    </div>
                    <div class="main-balance-wrap v3">
                        <div class="main-balance-name">
                            <div>
                                <span>USDT/{{ item.currency_t }}</span>
                                <b>Покупка</b>
                            </div>
                        </div>
                        <div class="main-balance-value">
                            <small>1.00 USDT = {{ item.rate_sell }} {{ item.currency_t }}</small>
                            <b>Курс</b>
                        </div>
                    </div>
                </template>
            </div>
            <div class="dashboard-personal-wrap p-0">
                <template v-for="item in currencies" :key="item">
                    <div class="main-balance-wrap v3" v-if="item.can_withdraw === true || item.can_deposit === true || item.can_buy === true || item.can_sell === true">
                        <div class="main-balance-name">
                            <img :src="item.logo" :alt="item.name">
                            <div>
                                <span>{{ item.currency }}</span>
                                <b>{{ item.name }}</b>
                            </div>
                        </div>
                        <div class="main-balance-value">
                            <span :class="String(item.difference).search('-') !== -1 ? 'text-danger' : 'text-success'">${{ item.rate }}</span>
                            <b>Курс</b>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchValue: '',
        }
    },
    created() {
        this.$store.dispatch('getFiatRates')
    },
    computed: {
        fiat() {
            return this.$store.getters.getFiatRates
        },
        currencies() {
            return this.$store.getters.getCurrencies
        },
    },
    watch: {
        fiat() {
            console.log(this.fiat)
        }
    }
}
</script>