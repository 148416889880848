export default {
    state: {
        NewsList: '',
        NewsDetail: ''
    },
    mutations: {
        setNewsList(state, payload) {
            state.NewsList = payload
        },
        setNewsDetail(state, payload) {
            state.NewsDetail = payload.detail
        }
    },
    actions: {
        async getNewsList({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/news'})
            .then(response => response.json())
            .then(data => {
                commit('setNewsList', data)
            })
        },
        async getNewsDetail({dispatch, commit}, NewsId) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/news/' + NewsId})
            .then(response => response.json())
            .then(data => {
                commit('setNewsDetail', data)
            })
        }
    },
    getters: {
        getNewsList(state) {
            return state.NewsList
        },
        getNewsDetail(state) {
            return state.NewsDetail
        }
    }
}