import router from "@/router"

export default {
    state: {
        activationResult: '',
        activationMessage: '',
    },
    mutations: {
        setActivationResult(state, payload) {
            state.activationResult = payload
        },
        setActivationMessage(state, payload) {
            state.activationMessage = payload
        }
    },
    actions: {
        async registration({dispatch, commit}, formData) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/auth/registration', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    router.push('/auth/activation/' + formData.email)
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async confirmationAccount({dispatch, commit}, guid) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/auth/activation/confirm/' + guid})
            .then(response => response.json())
            .then(data => {
                if(data.response === true && data.token) {
                    localStorage.expires = Math.round(Date.now() / 1000) + + 604800;
                    localStorage.isAuth = true
                    localStorage.token = data.token;
                    router.push('/dashboard')
                } else {
                    commit('setActivationResult', false)
                    commit('setActivationMessage', data.messages)
                    commit('notification', {message: data.messages})
                }
            })
        },
        async resend({dispatch, commit}, formData) {
            dispatch('sendRequest', {endpoint: '/auth/activation/resend', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response !== undefined && data.response === true) {
                    commit('notification', {message: data.messages, type: 'success'})
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async login({dispatch, commit}, formData) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/auth/login', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response !== undefined && data.response === true) {
                    // localStorage.expires = data.expires;
                    localStorage.expires = Math.round(Date.now() / 1000) + + 604800;
                    localStorage.isAuth = true
                    localStorage.token = data.token;
                    commit('notification', {message: 'You have successfully logged in', type: 'success'})
                    commit('twofactor', false)
                    router.replace('/dashboard')
                } else {
                    if(data.not_activated === true) {
                        router.push('/auth/activation/' + formData.email)
                        commit('notification', {message: data.messages})
                    } else {
                        if(data.security === '2fa') {
                            commit('twofactor', true)
                        }
                        commit('notification', {message: data.messages})
                    }
                }
            })
        },
        async restore({dispatch, commit}, formData) {
            dispatch('sendRequest', {endpoint: '/auth/forgot', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response !== undefined && data.response === true) {
                    commit('notification', {message: data.messages, type: 'success'})
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        logout() {
            localStorage.clear()
            router.push('/auth/login')
        }
    },
    getters: {
        getActivationResult(state) {
            return state.activationResult
        },
        getActivationMassage(state) {
            return state.activationMessage
        }
    }
}