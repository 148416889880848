export default {
    state: {
        modalStatus: false,
        selectName: '',
        selectValue: ''
    },
    mutations: {
        setModalStatus(state, payload) {
            state.modalStatus = payload
        },
        setSelect(state, payload) {
            state.selectName = payload.name
            state.selectValue = payload.value
        }
    },
    actions: {
        setModalStatus({commit}, payload) {
            commit('setModalStatus', payload)
        },
        setSelect({commit}, payload) {
            commit('setSelect', payload)
        }
    },
    getters: {
        getModalStatus(state) {
            return state.modalStatus
        },
        selectName(state) {
            return state.selectName
        },
        selectValue(state) {
            return state.selectValue
        }
    }
}