<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">
            <span>Продать криптовалюту</span>
            <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
        </h1>
        <div class="col-12 col-md-8 col-lg-8 col-lx-8">
            <div class="d-block mb-4 pt-2">
                <input type="search" class="form-input search-input" v-model="searchValue" placeholder="Поиск ...">
            </div>
            <div class="dashboard-personal-wrap p-0">
                <template v-for="item in currencies" :key="item">
                    <div class="main-balance-wrap v2" v-if="item.can_sell === true && search(item)">
                        <div class="main-balance-name">
                            <img :src="item.logo" :alt="item.name">
                            <div>
                                <span>{{ item.currency }}</span>
                                <b>{{ item.name }}</b>
                            </div>
                        </div>
                        <div class="main-balance-name v2">
                            <span>${{ item.rate }}</span>
                            <b>Цена</b>
                        </div>
                        <div class="main-balance-value">
                            <span>{{ item.balance }}</span>
                            <b :class="item.converted_balance > 0 ? 'text-success' : 'text-danger'">${{ item.converted_balance }}</b>
                        </div>
                        <div class="main-balance-action">
                            <router-link :to="'/dashboard/sell/' + item.currency" v-if="item.can_sell === true" class="green">Продать</router-link>
                            <a class="disabled" v-else>Продать</a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchValue: ''
        }
    },
    computed: {
        currencies() {
            return this.$store.getters.getCurrencies
        },
    },
    methods: {
        rateFormat(data) {
            const rates = []
            Object(data.rates).forEach((item, index) => {
                rates[index++] = item.price
            })
            return [{data: rates}]
        },
        search(item) {
            return (item.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1 || item.currency.toLowerCase().search(this.searchValue.toLowerCase()) !== -1) ? true : false
        }
    }
}
</script>