<template>
    <section class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3 footer-email">
                    <router-link to="/" class="footer-logo"></router-link>
                    <span class="label">Напишете нам</span>
                    <a href="mailto:business@xbt.kg" class="email-link">business@xbt.kg</a>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <span class="label">Быстрые ссылки</span>
                    <div class="footer-menu">
                        <router-link to="/" class="footer-nav ease">Главная</router-link>
                        <router-link to="/about" class="footer-nav ease">О компании</router-link>
                        <router-link to="/news" class="footer-nav ease">Новости</router-link>
                        <router-link to="/faq" class="footer-nav ease">Вопрос/Ответы</router-link>
                        <router-link to="/contacts" class="footer-nav ease">Контакты</router-link>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <span class="label">Регистрация</span>
                    <div class="footer-menu">
                        <router-link to="/auth/register" class="footer-nav ease">Регистрация</router-link>
                        <router-link to="/auth/login" class="footer-nav ease">Войти в личный кабинет</router-link>
                        <router-link to="/auth/restore" class="footer-nav ease">Забыли пароль?</router-link>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <span class="label">Условия использования</span>
                    <div class="footer-menu">
                        <router-link to="/rules" class="footer-nav ease">Публичная оферта</router-link>
                        <router-link to="/privacy-policy" class="footer-nav ease">Политика конфиденциальности</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="copyright">
        <div class="container">
            <span>Все права защищены 2024 &copy; XBT LLC</span>
            <span>Made with ❤️ by <a href="https://t.me/navisdevs" target="_blank" class="text-success">Navis Devs</a></span>
        </div>
    </div>
</template>