<template>
    <div class="container z-index auth-wrap">
        <div class="row">
            <div class="col-md-5 auth-form">
                <h1 class="title">Добро пожаловать</h1>
                <p class="subtitle">Забыли пароль?</p>
                <p class="text">Укажите ваш E-mail, который использовался при регистрации</p>
                <form v-on:submit.prevent="restore" class="form" autocomplete="off">
                    <div class="form-group">
                        <input type="email" class="form-input" v-model="email" placeholder="Введите e-mail" :disabled="loading" required>
                    </div>
                    <div class="py-3"></div>
                    <div class="text-center">
                        <button class="button button-default d-inline-block lg" type="submit" :disabled="loading">Сбросить пароль</button>
                        <span class="form-agree">Есть аккаунт? <router-link to="/auth/login">Вернуться к авторизации</router-link></span>
                    </div>
                </form>
            </div>
            <div class="col-md-7 d-flex justify-content-center align-items-center">
                <div class="auth-icon"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: ''
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        }
    },
    methods: {
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        restore() {
            if (!this.email || this.email.trim() === '') {
                this.notification('Введите E-mail')
            } else {
                this.$store.dispatch('restore', { email: this.email })
            }
        }
    }
}
</script>