<template>
    <section class="currencies">
        <div class="container">
            <h1 class="title text-center pb-2">Популярные криптовалюты</h1>
            <div class="currencies-table">
                <div class="row currencies-row currencies-table-header border-bottom">
                    <div class="col-6 col-md currencies-column m">Торговая пара</div>
                    <div class="col-6 col-md currencies-column m">Цена</div>
                    <div class="col-md currencies-column">Обьем 24ч (USDT)</div>
                    <div class="col-md currencies-column">Рынок</div>
                    <div class="col-md currencies-column">Действие</div>
                </div>
                <template v-for="(currency, index) in getMainData.currencies" :key="index">
                    <div class="row currencies-row border-bottom" v-if="currency.rate">
                        <div class="col-6 col-md currencies-column currencies-column-md m">
                            <img :src="currency.logo" :alt="currency.name">
                            <span>{{ currency.name }}</span>
                        </div>
                        <div class="col-6 col-md currencies-column currencies-column-md m">
                            ${{ currency.rate }}
                            <span class="md-block" :class="String(currency.difference).search('-') !== -1 ? 'text-danger' : 'text-success'">{{ currency.difference }}$</span>
                        </div>
                        <div class="col-md currencies-column currencies-column-md">
                            <span :class="String(currency.difference).search('-') !== -1 ? 'text-danger' : 'text-success'">{{ currency.difference }}$</span>
                        </div>
                        <div class="col-md currencies-column currencies-column-md">
                            <i class="market-icon" :class="String(currency.difference).search('-') !== -1 ? 'down' : 'up'"></i>
                        </div>
                        <div class="col-md currencies-column currencies-column-md currencies-buttons">
                            <router-link :to="'/dashboard/buy/' + currency.currency" class="currencies-button">Покупка</router-link>
                            <router-link :to="'/dashboard/buy/' + currency.currency" class="currencies-button active">Продажа</router-link>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    computed: {
        getMainData() {
            return this.$store.getters.getMainData
        }
    },
    methods: {
        rateFormat(data) {
            const rates = []
            Object(data.rates).forEach((item, index) => {
                rates[index++] = item.price
            })
            return [{data: rates}]
        }
    }
}
</script>