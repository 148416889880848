export default {
    state: {
        methods: '',
        depositAddress: '',
        fiatMethod: '',
        MKassaPaymentStatus: ''
    },
    mutations: {
        setMethods(state, payload) {
            state.methods = payload.methods
        },
        setDepositAddress(state, payload) {
            state.depositAddress = payload
        },
        setFiatMethod(state, payload) {
            state.fiatMethod = payload
        },
        clearFiatMethod(state) {
            state.fiatMethod = null
        },
        setMKassaPaymentStatus(state, payload) {
            state.MKassaPaymentStatus = payload
        }
    },
    actions: {
        async getMethods({dispatch, commit}, method) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/replenish/methods/' + method})
            .then(response => response.json())
            .then(data => {
                commit('setMethods', data)
            })
        },
        async getDepositAddress({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/replenish/cryptocurrency?method=' + data.slug + (data.network !== undefined ? '&network=' + data.network : '')})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    commit('setDepositAddress', data)
                }
            })
        },
        async createFiatMethod({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/replenish/fiat', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    commit('setFiatMethod', data)
                }
            })
        },
        async checkMKassaPayment({dispatch, commit}, formData) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/operations', data: formData})
            .then(response => response.json())
            .then(data => {
                commit('setMKassaPaymentStatus', data.operations[1])
            })
        },
        clearFiatMethod({commit}) {
            commit('clearFiatMethod')
        }
    },
    getters: {
        getMethods(state) {
            return state.methods
        },
        getDepositAddress(state) {
            return state.depositAddress
        },
        getFiatMethod(state) {
            return state.fiatMethod
        },
        getMKassaPaymentStatus(state) {
            return state.MKassaPaymentStatus
        }
    }
}